import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Newsletter from '../components/Newsletter'
import styled from 'styled-components'
import imageReacttimeline from '../images/projects/react-timeline.png'
import imageGraphqlstack from '../images/projects/graphqlstack.png'
import ImageGatsbyPostManagerLogo from '../images/projects/gatsby-post-manager-logo.png'

const projects = [
  { name: 'GraphQLStack.com', img: imageGraphqlstack, slug: 'graphqlstack' },
  { name: 'React Timeline', img: imageReacttimeline, slug: 'react-timeline' },
  {
    name: 'Gatsby Post Manager',
    img: ImageGatsbyPostManagerLogo,
    slug: 'gatsby-post-manager',
    objectFit: 'none',
    backgroundColor: '#0E80C7',
  },
]

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-auto-rows: 340px;
  grid-gap: 1rem;
  margin: 0;
  padding: 0;
  justify-items: center;
`

const GridItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;

  img {
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    border-radius: 6px;
    background-color: ${props => props.backgroundColor || '#fff'};
    max-width: 356px;
    height: 252px;
    object-fit: ${props => props.objectFit || 'cover'};
  }
`

export default function Articles({ data, location }) {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Projects"
        keywords={[`projects`, `python`, `javascript`, `react`, 'open source']}
      />
      <section>
        <h1>Projects</h1>
        <p>
          Below are some of my open source projects. Click on any of them for
          more info.
        </p>
        <Grid>
          {projects.map((project, i) => (
            <Link to={`projects/${project.slug}`} key={i}>
              <GridItem
                objectFit={project.objectFit}
                backgroundColor={project.backgroundColor}
              >
                <h2>{project.name}</h2>
                <img src={project.img} alt={`Image for ${project.name}`} />
              </GridItem>
            </Link>
          ))}
        </Grid>
      </section>
      <Newsletter
        body="Subscribe to be notified when I publish new content and release new projects."
        referrer="projects page"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Projects {
    site {
      siteMetadata {
        title
      }
    }
  }
`
